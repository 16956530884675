import React, { Suspense } from "react";
import HomeHeroCarousel from "./HomeHeroCarousel";
import FeaturesBlock from "./FeaturesBlock";
import HeaderBlock from "./HeaderBlock";
import Fallback from "../Core/Fallback";
import styled from "styled-components";

const TextImageBlock = React.lazy(() => import("./TextImageBlock"));
const PressBlock = React.lazy(() => import("./PressBlock"));
const ReviewCarousel = React.lazy(() => import("./ReviewCarousel"));
const QuizCtaBlock = React.lazy(() => import("../Core/QuizCtaBlock"));
const ProductsCalloutBlock = React.lazy(() => import("./ProductsCalloutBlock"));
const BlogCallout = React.lazy(() => import("./BlogCallout"));
const SubscribeBanner = React.lazy(() => import("./SubscribeBanner"));
const Instafeed = React.lazy(() => import("./Instafeed"));
const ShopByConcern = React.lazy(() => import("./ShopByConcern"));
const ExploreOurRange = React.lazy(() => import("./ExploreOurRange"));
const DoctorBlock = React.lazy(() => import("./DoctorBlock"));
const IngredientsBlock = React.lazy(() => import("./IngredientsBlock"));

const Background = styled.div`
  // background-image: linear-gradient(0deg,#fff 43%,#fffffe 0,#f7f5f0 86%);
  background-color: #fff;
`

const PageContents = ({ blocks }) => {

  const pageBlocks = [];
  let features = [];
  let press = [];
  const isSSR = typeof window === "undefined";
  let exploreOurRangeInserted = false;
  let shoByConcernInserted = false;
  let doctorBlockInserted = false;
  let IngredientsBlockInserted = false;

  blocks && blocks.forEach((block, index) => {
    const { __typename: type, title } = block;

    if (type === "ContentfulHeroCarousel") {
      pageBlocks.push(<HomeHeroCarousel {...block} key={index} />);
    }

    // if (type === "ContentfulSubscribeBanner") {
    //   pageBlocks.push(<React.Fragment key={index}>{!isSSR && <Suspense fallback={<Fallback />}><SubscribeBanner {...block} key={index} /></Suspense>}</React.Fragment>);
    // }

    if (type === "ContentfulTitleTextBlock") {
      pageBlocks.push(<HeaderBlock {...block} key={index} />);
    }

   // Temp
    if (!shoByConcernInserted) {
      pageBlocks.push(<React.Fragment key="shop-by-concern">{!isSSR && <Suspense fallback={<Fallback />}><ShopByConcern /></Suspense>}</React.Fragment>);
      shoByConcernInserted = true;
    }

    if (type === "ContentfulFeature") {
      features.push(block);
    }

    if (
      features.length !== 0 &&
      index ===
        blocks.map((el) => el.__typename).lastIndexOf("ContentfulFeature")
    ) {
      pageBlocks.push(<React.Fragment key={index}>{!isSSR && <Suspense fallback={<Fallback />}><FeaturesBlock features={features} /></Suspense>}</React.Fragment>);
    }

    if (type === "ContentfulTextImageBlock") {
      pageBlocks.push(<React.Fragment key={index}>{!isSSR && <Suspense fallback={<Fallback />}><TextImageBlock {...block} /></Suspense>}</React.Fragment>);
    }

    if (type === "ContentfulPress") {
      press.push(block);
    }
    if (
      press.length !== 0 &&
      index === blocks.map((el) => el.__typename).lastIndexOf("ContentfulPress")
    ) {
      pageBlocks.push(<React.Fragment key={index}>{!isSSR && <Suspense fallback={<Fallback />}><PressBlock press={press} index={index} /></Suspense>}</React.Fragment>);
    
      // Temp
      if (!doctorBlockInserted) {
        pageBlocks.push(<React.Fragment key="doctor">{!isSSR && <Suspense fallback={<Fallback />}><DoctorBlock /></Suspense>}</React.Fragment>);
        doctorBlockInserted = true;
      }

      // Temp
      if (!exploreOurRangeInserted) {
        pageBlocks.push(<React.Fragment key="explore-our-range">{!isSSR && <Suspense fallback={<Fallback />}><ExploreOurRange /></Suspense>}</React.Fragment>);
        exploreOurRangeInserted = true;
      }
    }

    if (type === "ContentfulReviewsCarousel") {
      // Temp
        if (!IngredientsBlockInserted) {
            pageBlocks.push(<React.Fragment key="ingredients-block">{!isSSR && <Suspense fallback={<Fallback />}><IngredientsBlock /></Suspense>}</React.Fragment>);
            IngredientsBlockInserted = true;
        }

      pageBlocks.push(<React.Fragment key={index}>{!isSSR && <Suspense fallback={<Fallback />}><ReviewCarousel {...block} /></Suspense>}</React.Fragment>);
    }
    if (type === "ContentfulQuizCallout") {
      pageBlocks.push(<React.Fragment key={index}>{!isSSR && <Suspense fallback={<Fallback />}><QuizCtaBlock {...block} /></Suspense>}</React.Fragment>);
    }
    if (type === "ContentfulProductsCallout") {
      pageBlocks.push(<React.Fragment key={index}>{!isSSR && <Suspense fallback={<Fallback />}><ProductsCalloutBlock {...block} index={index} /></Suspense>}</React.Fragment>);
    }
    if (type === "ContentfulBlogCallout") {
      pageBlocks.push(<React.Fragment key={index}>{!isSSR && <Suspense fallback={<Fallback />}><BlogCallout {...block} /></Suspense>}</React.Fragment>);
    }
    if (type === "ContentfulLinkList" && title === "Instafeed Slider") {
      pageBlocks.push(<React.Fragment key={index}>{!isSSR && <Suspense fallback={<Fallback />}><Instafeed {...block} /></Suspense>}</React.Fragment>);
    }
  });

  const arrayMove = (arr, fromIndex, toIndex) => {
    const elem = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, elem);
  }

  // 0 : ticker
  // 1 : 
  // 2 : Shop by concern
  // 3 : Elimenate flakes
  // 4 : Product Callout sets
  // 5 : Product Callout sets cta
  // 6 : Press
  // 7 : Doctor
  // 8 : Explore our range
  // 9 : Product Callout Single, cta
  // 10 : Ingredients
  // 11 : Review Carousel
  // 12 : Blog
  // 13 : Quiz Callout Value Prop - scratching
  // 14 : Social Media
  // 15: Quiz Callout Default Prop

  arrayMove(pageBlocks, 6, 3);
  arrayMove(pageBlocks, 7, 4);
  arrayMove(pageBlocks, 8, 1);
  arrayMove(pageBlocks, 8, 6);
  arrayMove(pageBlocks, 13, 12);  
  arrayMove(pageBlocks, 2, 5);

  return (
    <React.Fragment>
      <Background>{pageBlocks}</Background>
    </React.Fragment>
  );
}

export default PageContents;
